<div class="container-md ">
  <div class="row">
    <div class="col-md-8 mt-3 ">
      <p class="text-muted text-style text-decoration-underline">Retail Store Management</p>
      <p class="display-5 text-dark" >Jew's software crafted to meet your <span style="color: #2888FF;">every need</span> in your jewelry business
      </p>
      <p class="text-style">An effortless and user friendly jewelry management software</p>
    </div>
    <div class="col-md-4 d-flex justify-content-center mt-3 ">
      <img src="assets/new/meditation.svg" class="meditation" alt="">
    </div>
  </div>
</div>
<div class="container-md mt-7">
  <div class="row">
    <p class="fs-1 heading-2 text-center text-dark" >Retail Store Management Functionalities</p>
    <p class="text-style text-center">An effortless and user friendly jewelry management functions</p>
  </div>
  <div class="row ">
  <div class="col-md-6 mt-4 d-flex align-items-stretch" >
    <div class="card m-2" >
      <div class="card-body">
        <h5 class="card-title"><img src="assets/new/diversity.svg" class="img-fluid m-1" alt="">Purchase & Suppliers</h5>
        <p class="card-text ">Effectively manage every product purchase from your suppliers. Maintain detailed transaction records, including outstanding payments. Purchase with outstanding balances and make payments to your supplier later. Use the rate cut option to customize your payment terms.</p>
      </div>
    </div>
  </div>
  <div class="col-md-6 mt-4 d-flex align-items-stretch">
    <div class="card m-2" >
      <div class="card-body">
        <h5 class="card-title"><img src="assets/new/inventory.svg" class="img-fluid m-1" alt=""> Inventory</h5>
        <p class="card-text">Track every product, easily generate weight and MRP product barcodes, create a stunning product inventory showcase for your customers, filter and find products effortlessly, split barcodes, and obtain a stock summary list for the day to easily reconcile your stock at day's end, including counters management.</p>
      </div>
    </div>
  </div>
  </div>
  <div class="row ">
    <div class="col-md-6 mt-4 d-flex align-items-stretch" >
      <div class="card m-2" >
        <div class="card-body">
          <h5 class="card-title"><img src="assets/new/receipt.svg" class="img-fluid m-1" alt="">Product Estimation</h5>
          <p class="card-text ">We make it simple for your customers to understand estimates. Choose from various estimation modes that suit your needs. Easily handle charges such as VA, making, stone, and more. With our one-click estimation print function, you can swiftly share estimates. Plus, you can generate multiple product estimations all at once.</p>
        </div>
      </div>
    </div>
    <div class="col-md-6 mt-4 d-flex align-items-stretch">
      <div class="card m-2" >
        <div class="card-body">
          <h5 class="card-title"><img src="assets/new/bag.svg" class="img-fluid m-1" alt=""> Sales</h5>
          <p class="card-text">Enjoy a user-friendly sales billing process. Effortlessly manage sales returns, handle sales with outstanding balances, and keep track of every outstanding bill. Monitor your complete billing history and provide customers with easy-to-understand tax invoices.</p>
        </div>
      </div>
    </div>
    </div>
    <div class="row ">
      <div class="col-md-6 mt-4 d-flex align-items-stretch" >
        <div class="card m-2" >
          <div class="card-body">
            <h5 class="card-title"><img src="assets/new/grid.svg" class="img-fluid m-1" alt="">Old Jewelry</h5>
            <p class="card-text ">Effortlessly estimate the value of customers' old jewelry, simply purchase their old jewelry, track every old jewelry purchase, and seamlessly use the old jewelry payment mode in your billings.</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 mt-4 d-flex align-items-stretch">
        <div class="card m-2" >
          <div class="card-body">
            <h5 class="card-title"><img src="assets/new/customer.svg" class="img-fluid m-1" alt=""> Customers</h5>
            <p class="card-text">Easily create customer profiles with all their details. Keep track of each customer's outstanding balances. Generate customer credit scores based on their outstanding payment history. Automatically send due balance and special offer messages with WhatsApp integration.</p>
          </div>
        </div>
      </div>
      </div>
      <div class="row ">
        <div class="col-md-6 mt-4 d-flex align-items-stretch" >
          <div class="card m-2" >
            <div class="card-body">
              <h5 class="card-title"><img src="assets/new/staff.svg" class="img-fluid m-1" alt="">Staff</h5>
              <p class="card-text ">Effortlessly create detailed staff profiles. Manage their roles by granting access only to the necessary application pages. We keep transaction histories hidden from your staff. You can also generate incentives based on their sales history and seamlessly manage attendance with biometric device integration.</p>
            </div>
          </div>
        </div>
        <div class="col-md-6 mt-4 d-flex align-items-stretch">
          <div class="card m-2" >
            <div class="card-body">
              <h5 class="card-title"><img src="assets/new/box.svg" class="img-fluid m-1" alt=""> Orders</h5>
              <p class="card-text">Create detailed orders with ease, including video and audio records. Stay updated on your order's progress, manage the supplier issue receiving process. Check key points like product verification, pricing, and more, and automatically send messages to your customers when their order items are ready for delivery. </p>
            </div>
          </div>
        </div>
        </div>
        <div class="row ">
          <div class="col-md-6 mt-4 d-flex align-items-stretch" >
            <div class="card m-2" >
              <div class="card-body">
                <h5 class="card-title"><img src="assets/new/repair.svg" class="img-fluid m-1" alt="">Repairs</h5>
                <p class="card-text ">Create detailed repairs with ease, including video and audio records. Stay updated on your repair’s progress, manage the worker issue receiving process. Check key points like product verification, pricing, and more, and automatically send messages to your customers when their repair items are ready for delivery. </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 mt-4 d-flex align-items-stretch">
            <div class="card m-2" >
              <div class="card-body">
                <h5 class="card-title"><img src="assets/new/overview.svg" class="img-fluid m-1" alt=""> Schemes</h5>
                <p class="card-text">Seamlessly onboard your customers onto schemes. Set up customized scheme templates to offer the schemes you prefer, designed to provide good margins. Easily announce scheme winners with an automated messaging system. Scheme customers can conveniently pay scheme amounts through the mobile application from the comfort of their homes.</p>
              </div>
            </div>
          </div>
          </div>
          <div class="row ">
            <div class="col-md-6 mt-4 d-flex align-items-stretch" >
              <div class="card m-2" >
                <div class="card-body">
                  <h5 class="card-title"><img src="assets/new/report.svg" class="img-fluid m-1" alt="">Reports</h5>
                  <p class="card-text ">Access an array of detailed reports to keep you informed. These include average metal rates, total metal sales weight, monthly sales analytics, product counts, top transaction categories, estimations and tax invoices generated, order progress, pieces and weights of your metals, bank and cash balances, outstanding payments to suppliers, and amounts to collect from clients.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 mt-4 d-flex align-items-stretch">
              <div class="card m-2" >
                <div class="card-body">
                  <h5 class="card-title"><img src="assets/new/arrow.svg" class="img-fluid m-1" alt=""> More</h5>
                  <p class="card-text">Jew's retail store management offers a wide array of features and functionalities. We are continuously working on advancing our features to keep Jew's at the forefront of innovation.</p>
                </div>
              </div>
            </div>
            </div>
  </div>
