import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {  } from 'protractor';

@Component({
  selector: 'app-bussiness',
  templateUrl: './bussiness.component.html',
  styleUrls: ['./bussiness.component.scss']
})
export class BussinessComponent implements OnInit {
  @Output() pageScroll = new EventEmitter<any>();
  strengths = [
    {
      src: "assets/img/filter_drama.svg",
      title: "Cutting edge cloud based solutions",
      description: "At Cixcent Technologies, we provide cutting-edge cloud-based solutions tailored specifically for jewelry stores. Our advanced technology ensures your business stays ahead of the curve in the digital age."
    },
    {
      src: "assets/img/encrypted.svg",
      title: "Unparalleled security",
      description: "We understand the importance of protecting your valuable data. Our software is fortified with state-of-the-art security measures, guaranteeing the safety of your business information and customer data."
    },
    {
      src: "assets/img/cloud_sync.svg",
      title: "Automated data backup",
      description: "Don't worry about losing vital information. Our products come with automated data backup, ensuring your data is always safe and accessible when you need it."
    },
    {
      src: "assets/img/support_agent.svg",
      title: "Dedicated client support",
      description: "Our commitment to your success is unwavering. We provide proactive and responsive client support to assist you with any questions or issues, ensuring your business runs smoothly."
    },
    {
      src: "assets/img/code.svg",
      title: "Continuous development",
      description: "Our team is committed to continuous development and improvement of our products, ensuring you always have access to the latest features and updates."
    },
    {
      src: "assets/img/diversity_4.svg",
      title: "Enhanced customer engagement",
      description: "Elevate your customer experience with features that help you engage with clients, manage loyalty programs, and provide personalized services."
    },
    {
      src: "assets/img/diversity_2.svg",
      title: "Multi user functionality",
      description: "Collaboration is made easy with our multi-user usability. You can grant access to your team, allowing for efficient teamwork and streamlined operations."
    },
    {
      src: "assets/img/currency_rupee.svg",
      title: "Cost efficient solutions",
      description: "We offer competitive pricing options that provide excellent value for your investment, helping you increase profitability while reducing operational costs."
    },
    {
      src: "assets/img/inventory.svg",
      title: "Efficient inventory management",
      description: "Our software includes advanced inventory management tools, making it simple to keep track of your jewelry pieces and optimize your stock levels."
    },
    {
      src: "assets/img/bolt.svg",
      title: "Scalability for growth",
      description: "Our solutions are designed to scale with your business. Whether you're a small boutique or a large retail chain, we can accommodate your needs as you grow."
    },
    {
      src: "assets/img/communities.svg",
      title: "User friendly interface",
      description: "Our intuitive and user-friendly interface ensures that you and your team can navigate the software with ease, reducing the learning curve and enhancing productivity."
    },
    {
      src: "assets/img/devices.svg",
      title: "Multi device compatibility",
      description: "We understand that flexibility is key. Our software is designed to work seamlessly on multiple devices, giving you the freedom to manage your jewelry store from anywhere."
    }
  ];
  constructor() { }

  ngOnInit() {
  }

}
