<div class="container-fluid pt-4 mt-3 px-5" style="margin-bottom: -150px;">
  <div class="row">
    <div class="col-lg-7 mt-3 ">
      <p class="display-5 text-dark me-5 d-flex justcontent-center" style="font-weight: 600;"> Transform Your Jewelry
        Business with Our Comprehensive ERP Solution
      </p>
      <p class="fs-5 text-dark mt-4 me-5">Transform your jewelry business with our comprehensive ERP solution designed
        specifically for the industry. Streamline operations, optimize inventory management, and enhance customer
        engagement to boost sales and profitability. Experience greater efficiency, insightful analytics, and seamless
        integration. Start your journey to success today and watch your business thrive!
      </p>
      <button class="btn text-white px-3 py-2 mt-5" style="background-color: #16AA51;" (click)="scrollToSection()">Book A Demo</button>
    </div>
    <div class="col-lg-5 d-flex justify-content-center mt-3 ">
      <img src="assets/img/mockups.svg" alt="">
    </div>
  </div>
</div>
<div class="container-fluid pt-4 mt-3 px-5">
  <p class="col-lg-1 px-1 text-center  text-dark fs-1" style="font-weight: 600;font-size: 1.5rem;">Features</p>
  <div class="row">
    <div class="col-lg-4 d-flex justify-content-center">
      <img src="assets/img/frame9.svg" alt="" class="p-3">
    </div>
    <div class="col-lg-8 mt-3">
      <div class="row">
        <div class="col-lg-4 mb-3">
          <div class="card p-3">
            <div class="d-flex">
              <span class="material-symbols-outlined" style="font-size: 80px;">
              inventory
            </span> 
            <div class="hover-container  ms-auto">
              <div class="hover-text text-white p-3" >
                Read More
              </div>
            </div>
            </div>
            <br>
            <span class=" mb-3" style="font-weight: 600;font-size: 1.5rem;">Inventory management</span>
            <p class="">Inventory management reduces costs and stockouts, improving efficiency and enhancing customer
              satisfaction.</p>
          </div>
        </div>
        <div class="col-lg-4 mb-3">
          <div class="card p-3">
            <span class="material-symbols-outlined" style="font-size: 80px;">
              receipt_long
            </span><br>
            <span class=" mb-3" style="font-weight: 600;font-size: 1.5rem;">Product Estimation</span>
            <p>Product estimation enhances pricing strategies, and boosts customer satisfaction through better service.
            </p>
          </div>
        </div>
        <div class="col-lg-4 mb-3">
          <div class="card p-3">
            <span class="material-symbols-outlined" style="font-size: 80px;">
              shopping_bag</span><br>
            <span class=" mb-3" style="font-weight: 600;font-size: 1.5rem;">Sales billing & Invoicing</span>
            <p>Streamlined sales billing and invoicing enhance accuracy, speed transactions, and improve customer
              experience.</p>
          </div>
        </div>
        <div class="col-lg-4 mb-3">
          <div class="card p-3">
            <span class="material-symbols-outlined" style="font-size: 80px;">
              diversity_3</span><br>
            <span class=" mb-3" style="font-weight: 600;font-size: 1.5rem;">Roles & Authorizations</span>
            <p>Defined roles and authorizations enhance security, streamline operations, and ensure accountability.</p>
          </div>
        </div>
        <div class="col-lg-4 mb-3">
          <div class="card p-3">
            <span class="material-symbols-outlined" style="font-size: 80px;">
              package_2</span><br>
            <span class=" mb-3" style="font-weight: 600;font-size: 1.5rem;">Order mangement</span>
            <p>Order management streamlines processing, improves accuracy, and enhances customer satisfaction.</p>
          </div>
        </div>
        <div class="col-lg-4 mb-3">
          <div class="card p-3">
            <span class="material-symbols-outlined" style="font-size: 80px;">
              handyman</span><br>
            <span class=" mb-3" style="font-weight: 600;font-size: 1.5rem;">Repair management</span>
            <p>Streamlines repair tracking, reduces turnaround time, and improves inventory management efficiency.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid pt-4 px-5">
  <div class="row">
    <div class="col-lg-8 mt-3">
      <div class="row">
        <div class="col-lg-4 mb-3">
          <div class="card p-3">
            <span class="material-symbols-outlined" style="font-size: 80px;">
              diversity_2
            </span><br>
            <span class=" mb-3" style="font-weight: 600;font-size: 1.5rem;">Purchase & Suppliers</span>
            <p>Effectively manage every product purchase from your suppliers. manage every suppliers.</p>
          </div>
        </div>
        <div class="col-lg-4 mb-3">
          <div class="card p-3">
            <span class="material-symbols-outlined" style="font-size: 80px;">
              grid_goldenratio
            </span><br>
            <span class=" mb-3" style="font-weight: 600;font-size: 1.5rem;">Old jewelry exchange</span>
            <p>Encourages sustainable practices, enhances customer loyalty, and provides unique inventory through
              trade-ins.</p>
          </div>
        </div>
        <div class="col-lg-4 mb-3">
          <div class="card p-3">
            <span class="material-symbols-outlined" style="font-size: 80px;">
              price_change</span><br>
            <span class=" mb-3" style="font-weight: 600;font-size: 1.5rem;">Price management</span>
            <p>Ensures competitive pricing, enhances profitability, and improves customer transparency and trust.</p>
          </div>
        </div>
        <div class="col-lg-4 mb-3 ">
          <div class="card p-3">
            <span class="material-symbols-outlined" style="font-size: 80px;">
              overview</span><br>
            <span class=" mb-3" style="font-weight: 600;font-size: 1.5rem;">Schemes</span>
            <p>Attracts customers, encourages repeat business, enhances engagement, and discounts.</p>
          </div>
        </div>
        <div class="col-lg-4 mb-3">
          <div class="card p-3">
            <span class="material-symbols-outlined" style="font-size: 80px;">
              diversity_1</span><br>
            <span class=" mb-3" style="font-weight: 600;font-size: 1.5rem;">Customer management</span>
            <p>Improves customer relationships, personalizes experiences, boosts retention.</p>
          </div>
        </div>
        <div class="col-lg-4 mb-3">
          <div class="card p-3">
            <span class="material-symbols-outlined" style="font-size: 80px;">
              finance</span><br>
            <span class=" mb-3" style="font-weight: 600;font-size: 1.5rem;">Financial accounting</span>
            <p>Financial tracking, enhances reporting, simplifies tax compliance, and improves cash flow management.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 d-flex justify-content-center">
      <img src="assets/img/applemac.svg" alt="" class="mt-5 pt-5 ">
    </div>
  </div>
</div>
<div class="container-fluid pt-4  px-5">
  <div class="row">
    <div class="col-lg-4 d-flex justify-content-center">
      <img src="assets/img/phones2.svg" alt="" class="p-3">
    </div>
    <div class="col-lg-8 mt-3">
      <div class="row">
        <div class="col-lg-4 mb-3">
          <div class="card p-3">
            <span class="material-symbols-outlined" style="font-size: 80px;">
              monitoring
            </span><br>
            <span class="mb-3" style="font-weight: 600;font-size: 1.5rem;">Reports & Analytics</span>
            <p>Tracks performance metrics, enhances inventory management, and identifies sales trends.</p>
          </div>
        </div>
        <div class="col-lg-4 mb-3">
          <div class="card p-3">
            <span class="material-symbols-outlined" style="font-size: 80px;">
              inventory
            </span><br>
            <span class=" mb-3" style="font-weight: 600;font-size: 1.5rem;">Stock tally</span>
            <p>Reduces discrepancies, streamlines stock management, enhances replenishment, and improves efficiency.</p>
          </div>
        </div>
        <div class="col-lg-4 mb-3">
          <div class="card p-3">
            <span class="material-symbols-outlined" style="font-size: 80px;">
              approval_delegation</span><br>
            <span class=" mb-3" style="font-weight: 600;font-size: 1.5rem;">Approvals</span>
            <p>Streamlines decision-making, ensures quality control, enhances accountability, and boosts customer trust.
            </p>
          </div>
        </div>
        <div class="col-lg-4 mb-3">
          <div class="card p-3">
            <span class="material-symbols-outlined" style="font-size: 80px;">
              sms</span><br>
            <span class=" mb-3" style="font-weight: 600;font-size: 1.5rem;">SMS & WhatsApp</span>
            <p>Enhances customer communication, provides instant updates, improves engagement and supports promotions.
            </p>
          </div>
        </div>
        <div class="col-lg-4 mb-3">
          <div class="card p-3">
            <span class="material-symbols-outlined" style="font-size: 80px;">
              point_of_sale</span><br>
            <span class=" mb-3" style="font-weight: 600;font-size: 1.5rem;">Point of sale (POS)</span>
            <p>Streamlines transactions, manages inventory, generates sales reports, and enhances operational
              efficiency.</p>
          </div>
        </div>
        <div class="col-lg-4 mb-3">
          <div class="card p-3">
            <span class="material-symbols-outlined" style="font-size: 80px;">
              currency_rupee</span><br>
            <span class=" mb-3" style="font-weight: 600;font-size: 1.5rem;">Credit management system</span>
            <p>Enhances cash flow, manages customer credit limits, reduces risks, and supports financial stability.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid" id="target-section">
  <p class="col-lg-3 text-dark fs-1 text-center" style="font-weight: 600;font-size: 1.5rem;"><span>Get a Free
      Demo</span></p>
  <div class="row">
    <div class="col-lg-6 d-flex justify-content-center">
      <img src="assets/img/frame8.svg" alt="" class="px-5">
    </div>
    <div class="col-lg-6 px-5 pt-5">
      <form [formGroup]="customerDemoDetails">
        <div class="row">
          <div class="col-md-6">
            <label>Name*</label>
            <mat-form-field appearance="outline" class="w-100">
              <input matInput placeholder="Enter Account Name" formControlName="name" maxlength="50">
            </mat-form-field>
            <mat-error *ngIf="f.name.errors && f.name.touched && f.name.errors.required" style="margin-top: -19px;">
              Name is required
            </mat-error>
          </div>
          <div class="col-md-6">
            <label>Mobile Number*</label>
            <mat-form-field appearance="outline" class="w-100">
              <input matInput placeholder="Enter Mobile Number" formControlName="mobileNumber" maxlength="10">
            </mat-form-field>
            <mat-error *ngIf="f.mobileNumber.errors && f.mobileNumber.touched && f.mobileNumber.errors.required"  style="margin-top: -19px;">
              Mobile Number is required
            </mat-error>
            <mat-error *ngIf="f.mobileNumber.errors && f.mobileNumber.touched && f.mobileNumber.errors.pattern"  style="margin-top: -19px;">
              enter valid mobile number
            </mat-error>
          </div>
        </div>
        <div class="row mt-2">
          <label>Store Name*</label>
          <mat-form-field appearance="outline" class="w-100">
            <input matInput placeholder="Enter Store name" formControlName="storeName" maxlength="10"  >
          </mat-form-field>
          <mat-error *ngIf="f.storeName.errors && f.storeName.touched && f.storeName.errors.required" style="margin-top: -19px;">
            Store name is required
          </mat-error>
        </div>
        <div class="row mt-2">
          <label>E- Mail</label>
          <mat-form-field appearance="outline" class="w-100">

            <input matInput placeholder="Enter your mail" formControlName="email" >
          </mat-form-field>
        </div>
        <div class="row px-2">
          <button class="btn text-white py-2" style="background-color: #16AA51;" type="submit">Get Your Free demo</button>
        </div>
      </form>
    </div>
  </div>
</div>