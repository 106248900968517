<div class="px-5" style=" height: 90vh;">
  <div class="row custom-margin mt-5">
    <div class="col text-left">
      <p class="fs-1 text-dark" style="font-weight:600">We are coming soon</p>
    </div>
  </div>
  <div class="container-fluid d-flex justify-content-center align-items-center mt-5">
    <div class="col-lg-4 d-flex justify-content-center">
      <img src="assets/img/sitting-boy.svg" class="meditation" alt="">
    </div>
  </div>
</div>
