import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-retail-page',
  templateUrl: './retail-page.component.html',
  styleUrls: ['./retail-page.component.scss']
})
export class RetailPageComponent implements OnInit {
  customerDemoDetails: FormGroup;
  @Input() scrollToDemo$ = new Observable<boolean>()
  constructor(
    private fb: FormBuilder,
    // private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.customerDemoDetails = this.fb.group({
      name: new FormControl(null,[Validators.required]),
      mobileNumber: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{10}$')]),
      storeName: new FormControl(null,[Validators.required]),
      email: new FormControl(null)
    })
    this.scrollToDemo$.subscribe((scroll)=>{
      if(scroll){
        this.scrollToSection();
      }
    })
  }

  get f(){
    return this.customerDemoDetails.controls;
  }

  scrollToSection(): void {
    const element = document.getElementById("target-section");
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  // viewInfo() {
  //   const modalRef = this.modalService.open(ModalInfoComponent, {
  //     size: 'md',
  //     keyboard: false,
  //     backdrop: 'static',
  //   });
  //   // modalRef.componentInstance.client = client;
  //   modalRef.result.then(
  //     () => {},
  //     () => {},
  //   );
  // }
  

}
