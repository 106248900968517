<div class="container-md ">
  <div class="row">
    <div class="col-md-8 mt-3 ">
      <p class="display-5 text-dark" > Streamline your <span style="color: #2888FF;">Jewelry Business</span> with effortless store management
      </p>
      <p class="text-style">Is your jewelry business running smoothly or stuck in chaos ?</p>
      <p class="text-style">Are you satisfied with the performance of your current software ?</p>
      <p class="text-style">Try our Jew’s with a 100% satisfaction guarantee or your money back</p>
      <p class="text-style">Effortlessly handle every aspect of your jewelry store, so you can shine as the ultimate Jewel Maestro</p>
      <a class="btn btn-dark px-4 p-2" href="#scrollspyHeading1">Book a free demo</a>
    </div>
    <div class="col-md-4 d-flex justify-content-center mt-3 ">
      <img src="assets/new/laptopMan.svg" class="img-fluid laptopman" alt="">
    </div>
  </div>
</div>

<div class="container-md mt-7">
  <div class="row">
    <p class="fs-1 heading-2 text-center text-dark" >Elevating those who adorn the world with elegance</p>
    <p class="text-style text-center">We continuously evolve, adapt, and enhance our technology to meet the diverse needs of our contemporary jewelry enthusiasts.</p>
  </div>
  <div class="row d-flex justify-content-center">
    <div class="col-md-10">
      <div class="row mt-2">
        <div class="col-md-4 middle-box">
          <img src="assets/new/group.svg" class="img-fluid " alt="">
         </div>
         <div class="col-md-4 middle-box">
          <div class="mt-2">
            <h2><strong>50+</strong></h2>
            <p class=" fs-5 text-style text-dark">Clients across India and expanding further</p>
          </div>
         </div>
         <div class="col-md-4 middle-box">
          <div class="mt-2">
            <h2><strong>30+</strong></h2>
            <p class="fs-5 text-style text-dark">Cities enriched by our on ground support team</p>
          </div>
         </div>
      </div>
    </div>
  </div>
</div>

<div class="container-md ">
  <div class="row">
    <p class="fs-1 heading-2 text-center text-dark" >Why Cixcent?</p>
    <p class="text-style text-center">Leading the jewelry store revolution</p>
  </div>
  <div class="row ">
  <div class="col-md-6 mt-4 d-flex align-items-stretch" >
    <div class="card m-2" >
      <div class="card-body">
        <h5 class="card-title"><img src="assets/new/cloud.svg" class="img-fluid m-1" alt="">Cutting edge cloud based solutions</h5>
        <p class="card-text ">At Cixcent Technologies, we provide cutting-edge cloud-based solutions tailored specifically for 
          jewelry stores. Our advanced technology ensures your business stays ahead of the curve in the digital age.</p>
      </div>
    </div>
  </div>
  <div class="col-md-6 mt-4 d-flex align-items-stretch">
    <div class="card m-2" >
      <div class="card-body">
        <h5 class="card-title"><img src="assets/new/lock.svg" class="img-fluid m-1" alt=""> Unparalleled security</h5>
        <p class="card-text">We understand the importance of protecting your valuable data. Our software is fortified with state-of-the-art security measures, guaranteeing the safety of your business information and customer data.</p>
      </div>
    </div>
  </div>
  </div>
  <div class="row ">
    <div class="col-md-6 mt-4 d-flex align-items-stretch">
      <div class="card m-2" >
        <div class="card-body">
          <h5 class="card-title"><img src="assets/new/cloud_arrow.svg" class="img-fluid m-1" alt=""> Automated data backup</h5>
          <p class="card-text">Don't worry about losing vital information. Our products come with automated data backup, ensuring your data is always safe and accessible when you need it.</p>
        </div>
      </div>
    </div>
    <div class="col-md-6 mt-4 d-flex align-items-stretch">
      <div class="card m-2" >
        <div class="card-body">
          <h5 class="card-title"><img src="assets/new/agent.svg" class="img-fluid m-2" alt="">Exceptional client support</h5>
          <p class="card-text">Our commitment to your success is unwavering. We provide proactive and responsive client support to assist you with any questions or issues, ensuring your business runs smoothly.
      </div>
    </div>
    </div>
</div>
<div class="row ">
  <div class="col-md-6 mt-4 d-flex align-items-stretch">
    <div class="card m-2" >
      <div class="card-body">
        <h5 class="card-title"><img src="assets/new/code.svg" class="img-fluid m-1" alt="">Continuous development</h5>
        <p class="card-text">Our team is committed to continuous development and improvement of our products, ensuring you always have access to the latest features and updates.</p>
      </div>
    </div>
  </div>
  <div class="col-md-6 mt-4 d-flex align-items-stretch">
    <div class="card m-2" >
      <div class="card-body">
        <h5 class="card-title"><img src="assets/new/devices.svg" class="img-fluid m-2" alt="">Multi device compatibility</h5>
        <p class="card-text">We understand that flexibility is key. Our software is designed to work seamlessly on multiple devices, giving you the freedom to manage your jewelry store from anywhere.
    </div>
  </div>
  </div>
</div>
<div class="row ">
  <div class="col-md-6 mt-4 d-flex align-items-stretch">
    <div class="card m-2" >
      <div class="card-body">
        <h5 class="card-title"><img src="assets/new/diversity.svg" class="img-fluid m-2" alt="">Multi user functionality</h5>
        <p class="card-text">Collaboration is made easy with our multi-user usability. You can grant access to your team, allowing for efficient teamwork and streamlined operations.</p>
      </div>
    </div>
  </div>
  <div class="col-md-6 mt-4 d-flex align-items-stretch">
    <div class="card m-2" >
      <div class="card-body">
        <h5 class="card-title"><img src="assets/new/diamond.svg" class="img-fluid m-2" alt="">Tailored for jewelry stores</h5>
        <p class="card-text">Collaboration is made easy with our multi-user usability. You can grant access to your team, allowing for efficient teamwork and streamlined operations.
    </div>
  </div>
  </div>
</div>
<div class="row ">
  <div class="col-md-6 mt-4 d-flex align-items-stretch">
    <div class="card m-2" >
      <div class="card-body">
        <h5 class="card-title"><img src="assets/new/inventory.svg" class="img-fluid m-1" alt="">Efficient inventory management</h5>
        <p class="card-text">Our software includes advanced inventory management tools, making it simple to keep track of your jewelry pieces and optimize your stock levels.</p>
      </div>
    </div>
  </div>
  <div class="col-md-6 mt-4 d-flex align-items-stretch">
    <div class="card m-2" >
      <div class="card-body">
        <h5 class="card-title"><img src="assets/new/people.svg" class="img-fluid m-1" alt="">Enhanced customer engagement</h5>
        <p class="card-text">Elevate your customer experience with features that help you engage with clients, manage loyalty programs, and provide personalized services.
    </div>
  </div>
  </div>
</div>
<div class="row ">
  <div class="col-md-6 mt-4 d-flex align-items-stretch">
    <div class="card m-2" >
      <div class="card-body">
        <h5 class="card-title"><img src="assets/new/communities.svg" class="img-fluid m-2" alt="">User friendly interface</h5>
        <p class="card-text">Our intuitive and user-friendly interface ensures that you and your team can navigate the software with ease, reducing the learning curve and enhancing productivity.</p>
      </div>
    </div>
  </div>
  <div class="col-md-6 mt-4 d-flex align-items-stretch">
    <div class="card m-2" >
      <div class="card-body">
        <h5 class="card-title"><img src="assets/new/rupee.svg" class="img-fluid m-1" alt="">Cost efficient solutions</h5>
        <p class="card-text">We offer competitive pricing options that provide excellent value for your investment, helping you increase profitability while reducing operational costs.
    </div>
  </div>
  </div>
</div>
<div class="row ">
  <div class="col-md-6 mt-4 d-flex align-items-stretch">
    <div class="card m-2" >
      <div class="card-body">
        <h5 class="card-title"><img src="assets/new/bolt.svg" class="img-fluid m-2" alt="">Scalability for growth</h5>
        <p class="card-text">Our solutions are designed to scale with your business. Whether you're a small boutique or a large retail chain, we can accommodate your needs as you grow.</p>
      </div>
    </div>
  </div>
  <div class="col-md-6 mt-4 d-flex align-items-stretch">
    <div class="card m-2" >
      <div class="card-body">
        <h5 class="card-title"><img src="assets/new/adjust.svg" class="img-fluid m-2" alt="">Stay ahead of the Competition</h5>
        <p class="card-text">By choosing Cixcent Technologies, you're choosing innovation, security, and the tools you need to stay ahead of the competition in the jewelry industry.
    </div>
  </div>
  </div>
</div>
</div>
<div class="container-md">
  <div class="row d-flex justify-content-center">
    <div class="col-md-10">
      <div class="row" id="scrollspyHeading1">
        <p class="fs-1 heading-2  text-dark" >Schedule a free demo today</p>
        <p class="text-style ">Connect with our team for in depth clarifications</p>
      </div>
      <div class="row">
        <div class="col-md-7">
          <div class="row">
            <form action="">
              <div class="inputGroup">
                <div class="my-2 py-2">
                  <input autocomplete="off" required="" type="text">
                  <label for="name">Name</label>
                </div>
                <div class="my-2 py-2">
                  <input autocomplete="off" required="" type="text">
                  <label for="name">Mobile number*</label>
                </div>
                <div class="my-2 py-2">
                  <input autocomplete="off" required="" type="text">
                  <label for="name">Store name*</label>
                </div>
                <div class="my-2 py-2">
                  <input autocomplete="off" required="" type="text">
                  <label for="name">Other details</label>
                </div>
                <div class="my-2 py-2">
                  <button class="btn btn-dark w-100 submit">Submit</button>
                </div>
            </div>
            </form>
          </div>
        </div>
        <div class="col-md-5 d-flex justify-content-center">
          <img src="assets/new/computerMan.svg" alt="" height="200" class="img-fluid">
        </div>
      </div>
    </div>
  </div>
</div>