<div class="container-md mb-5">
  <div class="row">
    <div class="col-md-9 mt-3 ">
      <p class="text-muted text-style text-decoration-underline">About Us</p>
      <p class="display-5 text-dark" >Hello! We're Cixcent 
        and we're here to empower  <span style="color: #2888FF;">Jewelry Stores</span>
      </p>
      <p class="text-style">We deliver solutions that drive revenue growth and elevate the customer experience for all types of jewelry stores</p>
    </div>
    <div class="col-md-3 d-flex justify-content-center mt-3 ">
      <img src="assets/new/waving_hello.svg" class="meditation" alt="">
    </div>
  </div>
</div>  

<div class="container-md mb-5">
  <div class="row d-flex justify-content-center ">
    <div class="col-md-3 d-flex justify-content-center mt-3 ">
      <img src="assets/new/peoplestanding.svg" class="img-fluid people" alt="">
    </div>
    <div class="col-md-9 mt-3">
      <p class="text-dark line-height mb-4">Our journey began in 2020 with a clear vision: to simplify and enhance the jewelry
         industry. We set out to provide exceptional features and top-notch client support to 
         help businesses facing challenges today. Starting with just four team members, we've now grown to a
          dedicated team of over 30 experts, each with more than 20 years of experience in the software industry,
         spanning various sectors. We've also sought valuable insights from jewelry industry veterans with over 20 years of domain knowledge.</p>

         <p class="text-dark line-height mb-4">Our primary mission is to create jewelry software's that's easy to use, with a user-friendly interface, scalable, 
          robust, and much more, all at an affordable price. We want to make it easy for retailers to make informed decisions, 
          incorporating modern concepts that are crucial for today's jewelry entrepreneurs. We're committed to delivering quality 
          solutions and continuously working to address the new challenges faced by the industry.</p>

          <p class="text-dark line-height">We're here to understand your needs and ensure that you find the perfect software solution that aligns with your
             jewelry business. So, don't wait any longer – get in touch with us and give us the opportunity to serve you.</p>
    </div>
  </div>
</div>  
<div class="container-md mt-7">
<div class="row d-flex justify-content-center ">
  <div class="col-md-9">
    <div class="row mb-2">
      <p class="fs-1 heading-2 text-center text-dark" >Transforming the Jewelry World</p>
      <p class="text-style text-center">Revolutionizing the jewelry sector</p>
    </div>
    <div class="row p-3 m-2">
      <div class="card" >
        <div class="card-body">
          <h5 class="card-title"><img src="assets/new/diversity.svg" class="img-fluid m-1" alt="">Innovation</h5>
          <p class="card-text ">Our success is rooted in an unwavering commitment to research and innovation, propelling our jewelry excellence for over two decades. Each piece we craft reflects our relentless pursuit of perfection, seamlessly blending innovation with tradition to create timeless beauty and sophistication.</p>
        </div>
      </div>
    </div>
    <div class="row p-3 m-2">
      <div class="card" >
        <div class="card-body">
          <h5 class="card-title"><img src="assets/new/diversity.svg" class="img-fluid m-1" alt="">Simplicity</h5>
          <p class="card-text ">We believe in crafting solutions for jewelry that require no deciphering of complicated instructions. Our aim is to offer seamless and intuitive solutions that elevate the jewelry experience without the need for manuals.</p>
        </div>
      </div>
    </div>
    <div class="row p-3 m-2">
      <div class="card p-2" >
        <div class="card-body">
          <h5 class="card-title"><img src="assets/new/diversity.svg" class="img-fluid m-1" alt="">Customer Support</h5>
          <p class="card-text ">We are committed to giving voice to our jewelry partners, ensuring their concerns are heard and their challenges are met with effective solutions. Your satisfaction is our priority, and we're dedicated to resolving any issues promptly and comprehensively.</p>
        </div>
      </div>
    </div>
    <div class="row p-3 m-2">
      <div class="card" >
        <div class="card-body">
          <h5 class="card-title"><img src="assets/new/diversity.svg" class="img-fluid m-1" alt="">Pricing</h5>
          <p class="card-text ">At Cixcent, we firmly advocate for transparent and cost-effective pricing in technology solutions. We believe in empowering jewelry businesses with fair and straightforward pricing, ensuring access to innovative tools without hidden costs.</p>
        </div>
      </div>
    </div>
  </div>
  </div>
  </div>

