
<header [ngClass]="{'scrolled': isScrolled}" class="fixed-top">
  <div class="container-lg">
 <nav class="navbar navbar-expand-lg navbar-light p-3 " [class.sticky]="isSticky">
    <a class="navbar-brand" href="#" (click)="setActiveTab(PageFlow.Home)" ><img src="assets/new/logo.svg" alt="Logo"></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="offcanvas offcanvas-end " tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasNavbarLabel">Cixcent</h5>
        <button type="button" class="btn-close text-reset " data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body justify-content-end">
        <ul class="navbar-nav">
          <li class="nav-item">
            <button class="nav-link  m-1 fw-bolder"
            [ngClass]="{'text-primary':activeTab === PageFlow.Product}" data-toggle="tab"
            (click)="setActiveTab(PageFlow.Product)" data-bs-dismiss="offcanvas" aria-label="Close" href="#">Products</button>
          </li>
          <li class="nav-item">
            <button class="nav-link fw-bolder m-1" [ngClass]="{'active text-primary':activeTab === PageFlow.Support}" data-toggle="tab"
            (click)="setActiveTab(PageFlow.Support)" data-bs-dismiss="offcanvas" aria-label="Close"  href="#">Support</button>
          </li>
          <li class="nav-item">
            <button class="nav-link fw-bolder m-1" [ngClass]="{'text-primary':activeTab === PageFlow.Aboutus}" data-toggle="tab"
            (click)="setActiveTab(PageFlow.Aboutus)" data-bs-dismiss="offcanvas" aria-label="Close"  href="#">About us</button>
          </li>
          <li class="nav-item">
            <a  href="#scrollspyHeading1" class="btn btn-dark m-1" data-toggle="tab"
            (click)="setActiveTab(PageFlow.Home)" data-bs-dismiss="offcanvas" aria-label="Close" >Free demo</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  </div>
</header>
<div class="space">

</div>
<div class="container-md">
  <ng-template [ngIf]="activeTab === PageFlow.Home">
    <app-home-page></app-home-page> 
  </ng-template>
  <ng-template [ngIf]="activeTab === PageFlow.Product">
    <app-product-page></app-product-page>
  </ng-template>
  <ng-template [ngIf]="activeTab === PageFlow.Support">
    <app-support-page></app-support-page>
  </ng-template>
  <ng-template [ngIf]="activeTab === PageFlow.Aboutus">
    <app-aboutus-page></app-aboutus-page>
  </ng-template>
</div>
<div class="container d-flex justify-content-end align-items-center ">
  <a href="#" (click)="redirectToWhatsApp()"><img src="assets/new/whatsapp1.svg" class="whatsapp cursor-pointer" alt=""></a>
</div>

<footer class="mb-3 mt-5">
  <hr class=" text-muted">
<div class="container-md">
<div class="row">
  <div class="col-md-3 mt-5">
    <p class="">Retail Store Management</p>
    <p class="text-muted">Wholesale Store Management</p>
    <p class="">Book A Free Demo</p>
  </div>
  <div class="col-md-3  mt-5">
    <p class="">Support</p>
    <p class="">About Us</p>
    <p class="">Contact Sales</p>
    <p class="">WhatsApp Sales</p>

  </div>
  <div class="col-md-4 mt-5">
    <p class="fs-5 text">Cixcent Technologies Private Limited</p>
    <span class="fs-6">2nd Floor, Plot No 109, Saibaba Officers Colony, Hi-tension Road, ECIL, Hyderabad, Telangana-500062, Bhārat.</span>
  </div>
  <div class="col-md-2 mt-5">
    <p class="fs-6 logo"><img src="assets/new/logo.svg" class="img-fluid logo " alt="Logo"></p>
    <p class="d-flex align-items-center"><span class="material-symbols-outlined footer-img text-muted ">
      call
      </span>+91 9000106052</p>
    <p class="d-flex align-items-center"><span class="material-symbols-outlined footer-img text-muted p-1">
      mail
      </span>   info@cixcent.com</p>
</div>
</div>
<div class="row mb-1">
  <div class="col-md-12 d-flex justify-content-center ">
    <span class="footertext"> <img src="assets/new/copyright.svg"> 2023 Cixcent Technologies Pvt, Ltd., Bhārat | Privacy | Terms | Cancellation & Refund </span>
  </div>
</div>
</div>
</footer>