import { Component, HostListener, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-header-latest',
  templateUrl: './header-latest.component.html',
  styleUrls: ['./header-latest.component.scss']
})
export class HeaderLatestComponent implements OnInit {
  currentPage: string = 'home';
  scrollToDemo$ = new BehaviorSubject<boolean>(false); 
  isSticky: boolean = false;
  isScrolled: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  setActiveTab(pageName: string,scroll?: boolean){
    this.currentPage = pageName;
    if(scroll){
      this.scrollToDemo$.next(true);
    }else{
      this.scrollToDemo$.next(false);
    }
  }

  @HostListener('window:scroll', [])
  onScroll() {
      this.isScrolled = window.scrollY > 0;
  }

  scrollPage(event: any){
    this.currentPage = 'retail';
    this.scrollToDemo$.next(true);

  }

}
