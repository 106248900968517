import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { HomeComponent } from './components/pages/home/home.component';
import { NavbarTwoComponent } from './components/layouts/navbar-two/navbar-two.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { RetailManagementComponent } from './components/pages/retail-management/retail-management.component';
import { MobileAppComponent } from './components/pages/mobile-app/mobile-app.component';
import { BullianAppComponent } from './components/pages/bullian-app/bullian-app.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { CareerComponent } from './components/pages/career/career.component';
import { MasterComponent } from './new-component/master/master.component';
import { HomePageComponent } from './new-component/home-page/home-page.component';
import { ProductPageComponent } from './new-component/product-page/product-page.component';
import { SupportPageComponent } from './new-component/support-page/support-page.component';
import { AboutusPageComponent } from './new-component/aboutus-page/aboutus-page.component';
import { DemoFormComponent } from './new-component/demo-form/demo-form.component';
import { ContactSalesComponent } from './new-component/contact-sales/contact-sales.component';
import { ContactSupportComponent } from './new-component/contact-support/contact-support.component';
import { CareerDetailsComponent } from './new-component/career-details/career-details.component';
import { PersonalDetailsComponent } from './new-component/career-details/personal-details/personal-details.component';
import { ContactInformationComponent } from './new-component/career-details/contact-information/contact-information.component';
import { QualificationDetailsComponent } from './new-component/career-details/qualification-details/qualification-details.component';
import { ProfessionalDetailsComponent } from './new-component/career-details/professional-details/professional-details.component';
import { PreviewComponent } from './new-component/career-details/preview/preview.component';
import { CompleteComponent } from './new-component/career-details/complete/complete.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatChipsModule} from '@angular/material/chips';
import { HomePageLatestComponent } from './latest-components/home-page-latest/home-page-latest.component';
import { HeaderLatestComponent } from './latest-components/header-latest/header-latest.component';
import { FooterLatestComponent } from './latest-components/footer-latest/footer-latest.component';
import { BussinessComponent } from './latest-components/bussiness/bussiness.component';
import { RetailPageComponent } from './latest-components/retail-page/retail-page.component';
import { MatCardModule } from '@angular/material/card';
import { WholeSaleLatestComponent } from './latest-components/whole-sale-latest/whole-sale-latest.component';
import { AboutUsLatestComponent } from './latest-components/about-us-latest/about-us-latest.component';
import { ContactUsLatestComponent } from './latest-components/contact-us-latest/contact-us-latest.component';
import { ModalInfoComponent } from './latest-components/modal-info/modal-info.component';


@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    FooterComponent,
    HomeComponent,
    NavbarTwoComponent,
    FaqComponent,
    ErrorComponent,
    RetailManagementComponent,
    MobileAppComponent,
    FooterLatestComponent,
    RetailPageComponent,
    BullianAppComponent,
    ModalInfoComponent,
    GalleryComponent,
    BussinessComponent,
    ContactComponent,
    CareerComponent,
    MasterComponent,
    HomePageComponent,
    ProductPageComponent,
    SupportPageComponent,
    AboutusPageComponent,
    DemoFormComponent,
    ContactSalesComponent,
    ContactSupportComponent,
    CareerDetailsComponent,
    PersonalDetailsComponent,
    ContactInformationComponent,
    QualificationDetailsComponent,
    ProfessionalDetailsComponent,
    PreviewComponent,
    CompleteComponent,
    HomePageLatestComponent,
    HeaderLatestComponent,
    WholeSaleLatestComponent,
    AboutUsLatestComponent,
    ContactUsLatestComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatCardModule 
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
