import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Careers, Pages } from 'src/enums/pages.enum';

@Injectable({
  providedIn: 'root'
})
export class PageChangeService {
  pageFlow$ = new BehaviorSubject<Pages>(
    Pages.Home
  );
  careerFlow$ = new BehaviorSubject<Careers>(
    Careers.PersonalDetails
  );

constructor() { }

}

export const validateFormArrayFields = (formGroup: FormGroup) => {
  Object.keys(formGroup.controls).forEach((field) => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: true });
    }
    if (control instanceof FormArray) {
      const formGroups = control.controls as FormGroup[];
      formGroups.forEach((formG: FormGroup) => {
        validateFormFields(formG);
      });
    }
  });
};

export const validateFormFields = (formGroup: FormGroup, enableScrolling: boolean = true) => {
  let firstErrorField: string | null = null;
  Object.keys(formGroup.controls).forEach((field) => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: true });
      const controlErrors: ValidationErrors = control.errors;
      if (controlErrors != null) {
        if (!firstErrorField) {
          // Store the first error field
          firstErrorField = field;
        }
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(
            'Key control: ' +
              field +
              ', keyError: ' +
              keyError +
              ', err value: ',
            controlErrors[keyError],
          );
        });
      }
    }
  });
}

