import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { HomeComponent } from './components/pages/home/home.component';
import { NavbarTwoComponent } from './components/layouts/navbar-two/navbar-two.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { RetailManagementComponent } from './components/pages/retail-management/retail-management.component';
import { MobileAppComponent } from './components/pages/mobile-app/mobile-app.component';
import { BullianAppComponent } from './components/pages/bullian-app/bullian-app.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { CareerComponent } from './components/pages/career/career.component';
import { MasterComponent } from './new-component/master/master.component';
import { HomePageComponent } from './new-component/home-page/home-page.component';
import { ProductPageComponent } from './new-component/product-page/product-page.component';
import { SupportPageComponent } from './new-component/support-page/support-page.component';
import { AboutusPageComponent } from './new-component/aboutus-page/aboutus-page.component';

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    FooterComponent,
    HomeComponent,
    NavbarTwoComponent,
    FaqComponent,
    ErrorComponent,
    RetailManagementComponent,
    MobileAppComponent,
    BullianAppComponent,
    GalleryComponent,
    ContactComponent,
    CareerComponent,
    MasterComponent,
    HomePageComponent,
    ProductPageComponent,
    SupportPageComponent,
    AboutusPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
