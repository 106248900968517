<div class="container-md mb-5">
  <div class="row">
    <div class="col-md-9 mt-3 ">
      <p class="text-muted text-style text-decoration-underline">Support</p>
      <p class="display-5 text-dark" >Welcome! Our mission is to <span style="color: #2888FF;">assist you</span> every
        step of the way
      </p>
      <p class="text-style">Get relevant and instant solutions for your Jew’s software related troubles!</p>
      <div class="d-flex">
        <a class="btn btn-dark px-4 p-2 Connect-btn" href="#mobileNumber">Connect with us</a>
        <a class="btn btn-dark px-4 p-2 mx-4 WhatsApp" href="#WhatsApp">WhatsApp</a>
      </div>
    </div>
    <div class="col-md-3 d-flex justify-content-center mt-3 ">
      <img src="assets/new/laptopwomen.svg" class="meditation" alt="">
    </div>
  </div>
</div>  

<div class="container-md">
  <div class="row d-flex justify-content-center">
    <div class="col-md-6 mt-4 d-flex align-items-stretch" >
      <div class="card m-2" >
        <div class="card-body">
          <h5 class="card-title"><img src="assets/new/time.svg" class="img-fluid m-1" alt="">24/7 Seamless online support</h5>
          <p class="card-text ">Receive personalized solutions and one-on-one guidance from our Jew's software experts</p>
        </div>
      </div>
    </div>
    <div class="col-md-6 mt-4 d-flex align-items-stretch">
      <div class="card m-2" >
        <div class="card-body">
          <h5 class="card-title"><img src="assets/new/storefront.svg" class="img-fluid m-1" alt=""> On ground assistance</h5>
          <p class="card-text">Receive personalized solutions and one-on-one guidance from our Jew's software experts</p>
        </div>
      </div>
    </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-6 mt-4 d-flex align-items-stretch" >
        <div class="card m-2" >
          <div class="card-body">
            <h5 class="card-title"><img src="assets/new/agent.svg" class="img-fluid m-1" alt="">Seamless query management</h5>
            <p class="card-text ">Your questions receive a unique ticket ID and are directed to the perfect department for expert assistance</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 mt-4 d-flex align-items-stretch">
        <div class="card m-2" >
          <div class="card-body">
            <h5 class="card-title"><img src="assets/new/training.svg" class="img-fluid m-1" alt="">Staff training</h5>
            <p class="card-text">Empower your team with complimentary software training, available both on-call and in-person as needed</p>
          </div>
        </div>
      </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-md-6 mt-4 d-flex align-items-stretch" >
          <div class="card m-2" >
            <div class="card-body">
              <h5 class="card-title"><img src="assets/new/language.svg" class="img-fluid m-1" alt="">Breaking language barriers</h5>
              <p class="card-text ">Our support team assists you in over 3 Indian languages, ensuring you're always understood</p>
            </div>
          </div>
        </div>
        </div>
</div>

<div class="container-md mt-7">
  <div class="row mb-3">
    <p class="fs-1 heading-2 text-center text-dark" >Got a problem? Connect with us, and we'll find the solution</p>
  </div>
  <div class="row d-flex justify-content-center">
    <div class="col-md-4">
      <div class="card m-4" id="mobileNumber">
        <div class="card-body text-center">
          <img src="assets/new/call.svg" class="img-fluid mx-auto" alt="">
          <p class="text-style text-center mt-1">Call us</p>
          <p class="head-style text-dark fs-3">+91 9000106042</p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card m-4" id="WhatsApp">
        <div class="card-body text-center">
          <img src="assets/new/whatsapp.svg" class="img-fluid mx-auto" alt="">
          <p class="text-style text-center mt-1">WhatsApp</p>
          <p class="head-style text-dark fs-3">+91 9000106052</p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card m-4">
        <div class="card-body text-center">
          <img src="assets/new/mail.svg" class="img-fluid mx-auto" alt="">
          <p class="text-style text-center mt-1">Mail to</p>
          <p class="head-style text-dark fs-3">sales@cixcent.com</p>
        </div>
      </div>
    </div>
  </div>
 </div>