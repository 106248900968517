<div class="container-fluid pt-4 mt-3 px-5">
  <div class="row">
    <div class="col-md-7 mt-3 ">
      <p class="display-5 text-dark" style="font-weight: 600;line-height: 68px;"> Streamline your <span
          style="color: #16AA51;">Jewelry Business</span>
        <span> with effortless store management</span>
      </p>
      <p class="fs-5 text-dark mt-4">Is your jewelry business running smoothly or stuck in chaos ? <br>
        Are you satisfied with the performance of your current software ?<br>
        Try our Jew’s with a 100% satisfaction guarantee or your money back<br>
        Effortlessly handle every aspect of your jewelry store, so you can shine as the ultimate Jewel Maestro
      </p>
      <button class="btn text-white px-3 py-2 mt-1" style="background-color: #16AA51;" (click)="pageScroll.emit(true)">Book A Demo</button>
    </div>
    <div class="col-md-5 d-flex justify-content-center mt-3 ">
      <img src="assets/img/Frame1.svg" class="img-fluid laptopman w-100" alt="">
    </div>
  </div>
</div>
<div class="container-fluid  pt-4 mt-5 px-5">
  <div class="row">
    <div class="col-lg-6">
      <div class="d-flex">
        <p class="display-5 text-dark" style="font-weight: 600;"> Why <span style="color: #16AA51;">ERP24K</span>
          <span> Jewellery Software is better for your business?</span>
        </p>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="row">
      <div class="col-md-6 mb-4" *ngFor="let item of strengths">
        <div class="card w-100 m-1">
          <div class="card-body">
            <p class="fs-4 text-dark card-title">
              <img [src]="item.src" class="img-fluid me-2" alt="Innovation Icon"><span style="font-weight: 500">{{item.title}}</span>
            </p>
            <p class="card-text fs-5">{{item.description}}</p>
          </div>
        </div>
      </div>
    </div>    
  </div>
</div>

<div class="container-fluid pt-4  px-5">
  <div class="row">
    <div class="col-lg-7 ">
      <p class="fs-1 text-dark" style="font-weight: 600;line-height: 68px;"> Our Accreditations  </p>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-md-6 mb-4">
      <div class="card  p-4 w-100 m-1">
        <div class="row">
          <div class="col-md-2">
            <img src="assets/img/vapt2.svg" alt="">
          </div>
          <div class="col-md-10 d-flex align-items-center">
            <p class="fs-5 text-dark" style="font-weight: 600;">ERP24K is also certified with VPAT (Vulnerability Product Acceptance Testing)</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 mb-4">
      <div class="card  p-4 w-100 m-1">
        <div class="row">
          <div class="col-md-2">
            <img src="assets/img/vapt1.svg" alt="">
          </div>
          <div class="col-md-10 d-flex align-items-center">
            <p class="fs-5 text-dark" style="font-weight: 600;">ERP24K is also certified with VAPT (Vulnerability Assessment and Penetration Testing)</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>