import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Pages } from 'src/enums/pages.enum';

@Injectable({
  providedIn: 'root'
})
export class PageChangeService {
  pageFlow$ = new BehaviorSubject<Pages>(
    Pages.Home
  );
constructor() { }

}
